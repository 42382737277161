<template>
    <div class="boxPairDev">
        <template>
            <el-form inline>
                <el-form-item label="位置搜索：">
                    <el-input @change="addressChange" style="width:150px" size="mini" v-model="pageData.params.address" clearable></el-input>&nbsp;&nbsp;
                </el-form-item>
                <el-form-item label="名称搜索：">
                    <el-input @change="nameChange" style="width:150px" size="mini" v-model="pageData.params.devNickname" clearable></el-input>&nbsp;&nbsp;
                </el-form-item>
                <el-form-item label="SN搜索：">
                    <el-input @change="SNChange" style="width:150px" size="mini" v-model="pageData.params.devSn" clearable></el-input>&nbsp;&nbsp;
                </el-form-item>
                <el-form-item size="large">
                    <el-button :type="pageData.butText == '添加配对'?'primary':'danger'" @click="addMatching" v-if="dataText == '已配对'">{{pageData.butText}}</el-button>
                    <el-button :type="'primary'" @click="addMatching2" v-if="dataText == '已配对'">二次配对</el-button>
                    <!-- 组件自调用 -->
                    <el-dialog
                      v-dialogDrag
                      title="添加配对"
                      :visible.sync="dialogpairedDev"
                      @close="closeDialogpairedDev" 
                      :close-on-click-modal="false"
                      append-to-body
                      width="1000px"
                      :key="'未配对'"
                    >
                    <pair-table
                      :dataText="'未配对'"
                      :deviceId="deviceId"
                      :settingId="settingId"
                      :address="address"
                      v-if="pairTable"
                      @destroy="destroy($event)"
                    ></pair-table>
                    </el-dialog>
                </el-form-item>
            </el-form>
            <el-table v-if="dataText == '已配对'" :data="pageData.dataTable" style="width: 100%" height="60vh" v-loading="pageData.loading" @selection-change="haveSelse" stripe highlight-current-row >
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column prop="address" label="位置" show-overflow-tooltip></el-table-column>
                <el-table-column prop="devNickname" label="设备名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="devSn" label="设备SN" show-overflow-tooltip></el-table-column>
            </el-table>
            <el-table v-else-if="dataText == '未配对'" :data="pageData.dataTable" style="width: 100%" height="60vh" v-loading="pageData.loading1" @selection-change="haveSelse" stripe highlight-current-row >
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column prop="address" label="位置" show-overflow-tooltip></el-table-column>
                <el-table-column prop="devNickname" label="设备名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="devSn" label="设备SN" show-overflow-tooltip></el-table-column>
            </el-table>
            <div v-if="dataText == '未配对'" slot="footer" class="addPairDev">
                <el-button type="primary" @click="addUnpaired">添 加</el-button>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'pair-table',
    props:['dataText',"deviceId","settingId","address"],
    data() {
        return {
            pageData:{
                butText:'添加配对',
                loading:false,
                loading1:false,
                dataTable:[],
                params:{
                    address:'',
                    devNickname:'',
                    devSn:'',
                },
            },
            pairTable:false,
            dialogpairedDev:false,
            pairedDevices:[],
            settingId2:this.settingId
        }
    },
    created() {
        if(this.dataText == '已配对'){
            this.pageData.loading = true
            console.log('执行');
            this.paired()
        }else if(this.dataText == '未配对'){
            this.pageData.loading1 = true
            this.pageData.params.address = this.address
            this.unpaired()
        }
    },
    methods: {
        paired(){
            console.log("触发");
            this.$axios({
                method: "get",
                // url:`/common2/jourDevicePairRecords/pairedRecords`,
                url:`/common2/centrolRelation/selectCentrol`,
                params: {
                    jkDeviceId: this.deviceId,
                    address:this.pageData.params.address,// 地址
                    name:this.pageData.params.devNickname,// 设备名称
                    deviceSN:this.pageData.params.devSn,// 设备SN
                }
            }).then((res) => {
                this.pageData.loading = false;
                this.pageData.dataTable = res.data.result
                this.pageData.dataTable.forEach((item,index)=>{
                    let hsAddCommunity = item.hsAddCommunity?item.hsAddCommunity:''
                    let hsRoomType = item.hsRoomType?item.hsRoomType:''
                    let hsAddDoorplateno = item.hsAddDoorplateno?item.hsAddDoorplateno:''
                    let address = `${hsAddCommunity+'\u3000'+hsRoomType+'\u3000'+hsAddDoorplateno}`
                    this.$set(item,'address',address)
                })
                console.log(this.pageData.dataTable);
            }).catch((err) => {
                this.pageData.loading = false;
                this.isError(err, this);
            });
        },
        unpaired(){
            //查询未配对
            this.$axios({
                method: "get",
                url: `common2/devices/pairableDevice/${this.deviceId}`,
                params: {
                    address:this.pageData.params.address,// 地址
                    name:this.pageData.params.devNickname,// 设备名称
                    deviceSN:this.pageData.params.devSn,// 设备SN
                    hsId:this.settingId2
                }
            })
            .then((res) => {
                this.pageData.loading1 = false;
                this.pageData.dataTable = res.data.result
                console.log(this.pageData.dataTable)
            })
            .catch((err) => {
                this.pageData.loading1 = false;
                this.isError(err, this);
            });
            console.log("没配对的数据")
        },
        haveSelse(selection){
            console.log(selection)
            if(selection.length>0){
                this.pageData.butText = '清除配对'
            }else{
                this.pageData.butText = '添加配对'
            }
            this.pairedDevices = selection
        },
        addressChange(){
            if(this.dataText == '已配对'){
                this.pageData.loading = true
                this.paired()
            }else if(this.dataText == '未配对'){
                this.pageData.loading1 = true
                this.unpaired()
            }
        },
        nameChange(){
            if(this.dataText == '已配对'){
                this.pageData.loading = true
                this.paired()
            }else if(this.dataText == '未配对'){
                this.pageData.loading1 = true
                this.unpaired()
            }
        },
        SNChange(){
            if(this.dataText == '已配对'){
                this.pageData.loading = true
                this.paired()
            }else if(this.dataText == '未配对'){
                this.pageData.loading1 = true
                this.unpaired()
            }
        },
        addMatching2(){
            let devicesArr = [];
            this.pairedDevices.forEach((item,index)=>{
                devicesArr.push(item.devicePaired)
            })
            this.$axios({
                method: 'post',
                url: `/common2/centrolRelation/addCentrol`,
                data:{
                    deviceId:this.deviceId,
                    devicePairedList:devicesArr,
                } 
            }).then(res => {
                if(res.data.status != 200){return}
                this.$emit('destroy',false)
                this.$message.success("二次配对成功");
                this.pageData.loading1 = false
            }).catch(err => {
                this.pageData.loading1 = false
                this.isError(err, this)
            })
            
        },
        addMatching(){
            console.log(this.settingId);
            if(this.pageData.butText =="添加配对"){
                this.pairTable = true
                this.dialogpairedDev = true
            }else if(this.pageData.butText =="清除配对"){
                this.pageData.loading = true
                let devicesArr = [];
                this.pairedDevices.forEach((item,index)=>{
                devicesArr.push(item.devicePaired)
                })
                this.$axios({
                    method: 'post',
                    url: `/common2/centrolRelation/removeCentrol`,
                    data:{
                        deviceId:this.deviceId,
                        devicePairedList:devicesArr,
                    } 
                }).then(res => {
                    this.$message.success("清除成功");
                    this.pageData.loading = false
                    this.paired()
                }).catch(err => {
                    this.pageData.loading = false
                    this.isError(err, this)
                })
            }

        },
        closeDialogpairedDev(){
            this.pairTable = false
        },
        addUnpaired(){
            this.pageData.loading1 = true
            let devicesArr = [];
            this.pairedDevices.forEach((item,index)=>{
                devicesArr.push(item.jourDeviceId)
            })
            this.$axios({
                method: 'post',
                url: `/common2/centrolRelation/addCentrol`,
                data:{
                    deviceId:this.deviceId,
                    devicePairedList:devicesArr,
                } 
            }).then(res => {
                if(res.data.code != 200){return}
                this.$emit('destroy',false)
                this.$message.success("添加成功");
                this.pageData.loading1 = false
            }).catch(err => {
                this.pageData.loading1 = false
                this.isError(err, this)
            })
            console.log("添加未配对")
        },
        destroy(event){
            this.pairTable = event
            this.dialogpairedDev = event
            this.pageData.loading = true
            this.paired()
        },
          
    },
}
</script>
<style lang="scss" scoped>
.addPairDev{
    margin-top: 10px;
    float: right;
}
.boxPairDev{
    overflow:hidden;
}
</style>